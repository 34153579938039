import posthog from 'posthog-js'

export default defineNuxtPlugin({
  name: 'posthog',
  parallel: true,
  setup(nuxtApp) {
    const vueApp = nuxtApp.vueApp
    const config = useRuntimeConfig()

    const posthogClient = posthog.init(config.public.posthogPublicKey, {
      api_host: config.public.posthogHost,
      ui_host: config.public.posthogUiHost,
      person_profiles: 'identified_only',
      capture_pageview: false,
      autocapture: false,
      loaded: (posthog) => {
        if (import.meta.env.MODE === 'development') posthog.debug()
      },
      sanitize_properties: (properties, event_name) => {
        properties.app = 'rackplanner_frontend'
        return properties
      }
    })

    const router = useRouter()
    router.afterEach((to) => {
      nextTick(() => {
        posthog.capture('$pageview', { current_url: to.fullPath })
      })
    })

    return {
      provide: {
        posthog: () => posthogClient
      }
    }
  }
})
