import payload_plugin_ALLXFGgbCP from "/Users/laurentlang/Documents/Websites/Online/Patchbox/pb-rack-planner-v3/frontend/node_modules/nuxt-vuefire/dist/runtime/payload-plugin.js";
import revive_payload_client_4sVQNw7RlN from "/Users/laurentlang/Documents/Websites/Online/Patchbox/pb-rack-planner-v3/frontend/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/Users/laurentlang/Documents/Websites/Online/Patchbox/pb-rack-planner-v3/frontend/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/Users/laurentlang/Documents/Websites/Online/Patchbox/pb-rack-planner-v3/frontend/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/Users/laurentlang/Documents/Websites/Online/Patchbox/pb-rack-planner-v3/frontend/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/Users/laurentlang/Documents/Websites/Online/Patchbox/pb-rack-planner-v3/frontend/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/Users/laurentlang/Documents/Websites/Online/Patchbox/pb-rack-planner-v3/frontend/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/Users/laurentlang/Documents/Websites/Online/Patchbox/pb-rack-planner-v3/frontend/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_zbacBAXhj0 from "/Users/laurentlang/Documents/Websites/Online/Patchbox/pb-rack-planner-v3/frontend/.nuxt/modules/@nuxt-scripts/plugin.mjs";
import components_plugin_KR1HBZs4kY from "/Users/laurentlang/Documents/Websites/Online/Patchbox/pb-rack-planner-v3/frontend/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/Users/laurentlang/Documents/Websites/Online/Patchbox/pb-rack-planner-v3/frontend/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import floating_vue_EIcJ7xiw0h from "/Users/laurentlang/Documents/Websites/Online/Patchbox/pb-rack-planner-v3/frontend/.nuxt/floating-vue.mjs";
import plugin_client_pNVVgm7Ud2 from "/Users/laurentlang/Documents/Websites/Online/Patchbox/pb-rack-planner-v3/frontend/node_modules/nuxt-vuefire/dist/runtime/app/plugin.client.js";
import vuefire_auth_plugin_client_cCwkwh1j5u from "/Users/laurentlang/Documents/Websites/Online/Patchbox/pb-rack-planner-v3/frontend/.nuxt/vuefire-auth-plugin.client.mjs";
import vuefire_plugin_8cq4FnzXKb from "/Users/laurentlang/Documents/Websites/Online/Patchbox/pb-rack-planner-v3/frontend/.nuxt/vuefire-plugin.mjs";
import plugin_client_7d3Ap34qR4 from "/Users/laurentlang/Documents/Websites/Online/Patchbox/pb-rack-planner-v3/frontend/node_modules/nuxt-vuefire/dist/runtime/app-check/plugin.client.js";
import posthog_ulvgx15Dgc from "/Users/laurentlang/Documents/Websites/Online/Patchbox/pb-rack-planner-v3/frontend/plugins/posthog.ts";
import sentry_3AyO8nEfhE from "/Users/laurentlang/Documents/Websites/Online/Patchbox/pb-rack-planner-v3/frontend/plugins/sentry.ts";
import vue_final_modal_pML93k5qcp from "/Users/laurentlang/Documents/Websites/Online/Patchbox/pb-rack-planner-v3/frontend/plugins/vue-final-modal.ts";
export default [
  payload_plugin_ALLXFGgbCP,
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_zbacBAXhj0,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  floating_vue_EIcJ7xiw0h,
  plugin_client_pNVVgm7Ud2,
  vuefire_auth_plugin_client_cCwkwh1j5u,
  vuefire_plugin_8cq4FnzXKb,
  plugin_client_7d3Ap34qR4,
  posthog_ulvgx15Dgc,
  sentry_3AyO8nEfhE,
  vue_final_modal_pML93k5qcp
]