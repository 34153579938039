<template>
  <main class="bg-gray-950 text-white w-full min-h-screen flex justify-center items-center">
    <div class="flex flex-col items-center text-center max-w-xl">
      <PatchboxLogo color="#FFF" />
      <h1 class="text-3xl md:text-5xl font-bold leading-tight md:leading-tight mt-10 mb-6">Error {{ error.statusCode }}</h1>
      <p class="text-sm md:text-base mb-10">{{ error.statusCode === 404 ? error.statusMessage || "Sorry, this page isn't available." : 'Something has gone wrong - sorry about that!' }}</p>
      <button class="white-button" @click="handleError">Go to Homepage</button>
    </div>
  </main>
</template>

<script setup>
const props = defineProps({ error: Object })

const handleError = () => clearError({ redirect: '/' })
</script>
