import { default as account_45delete10RUBwsNhgMeta } from "/Users/laurentlang/Documents/Websites/Online/Patchbox/pb-rack-planner-v3/frontend/pages/account-delete.vue?macro=true";
import { default as account_45deletednmLGfXm3MxMeta } from "/Users/laurentlang/Documents/Websites/Online/Patchbox/pb-rack-planner-v3/frontend/pages/account-deleted.vue?macro=true";
import { default as accountu1ihb6BIoxMeta } from "/Users/laurentlang/Documents/Websites/Online/Patchbox/pb-rack-planner-v3/frontend/pages/account.vue?macro=true";
import { default as dashboardTuoGKyjkPRMeta } from "/Users/laurentlang/Documents/Websites/Online/Patchbox/pb-rack-planner-v3/frontend/pages/dashboard.vue?macro=true";
import { default as forgot_45passwordwWn8TxuxAHMeta } from "/Users/laurentlang/Documents/Websites/Online/Patchbox/pb-rack-planner-v3/frontend/pages/forgot-password.vue?macro=true";
import { default as indexkykUseuhTMMeta } from "/Users/laurentlang/Documents/Websites/Online/Patchbox/pb-rack-planner-v3/frontend/pages/index.vue?macro=true";
import { default as loginnrQSlgT6moMeta } from "/Users/laurentlang/Documents/Websites/Online/Patchbox/pb-rack-planner-v3/frontend/pages/login.vue?macro=true";
import { default as password_45reset_45successmY6n5QurlRMeta } from "/Users/laurentlang/Documents/Websites/Online/Patchbox/pb-rack-planner-v3/frontend/pages/password-reset-success.vue?macro=true";
import { default as planA2HdYnql3XMeta } from "/Users/laurentlang/Documents/Websites/Online/Patchbox/pb-rack-planner-v3/frontend/pages/plan.vue?macro=true";
import { default as quote_45successT62oaYm5hjMeta } from "/Users/laurentlang/Documents/Websites/Online/Patchbox/pb-rack-planner-v3/frontend/pages/quote-success.vue?macro=true";
import { default as quoteFSFyScYH7WMeta } from "/Users/laurentlang/Documents/Websites/Online/Patchbox/pb-rack-planner-v3/frontend/pages/quote.vue?macro=true";
import { default as register17cm9iNkGmMeta } from "/Users/laurentlang/Documents/Websites/Online/Patchbox/pb-rack-planner-v3/frontend/pages/register.vue?macro=true";
import { default as sandbox3cGGT8g7xhMeta } from "/Users/laurentlang/Documents/Websites/Online/Patchbox/pb-rack-planner-v3/frontend/pages/sandbox.vue?macro=true";
import { default as useractionHGeYnYYhO5Meta } from "/Users/laurentlang/Documents/Websites/Online/Patchbox/pb-rack-planner-v3/frontend/pages/useraction.vue?macro=true";
export default [
  {
    name: "account-delete",
    path: "/account-delete",
    meta: account_45delete10RUBwsNhgMeta || {},
    component: () => import("/Users/laurentlang/Documents/Websites/Online/Patchbox/pb-rack-planner-v3/frontend/pages/account-delete.vue").then(m => m.default || m)
  },
  {
    name: "account-deleted",
    path: "/account-deleted",
    component: () => import("/Users/laurentlang/Documents/Websites/Online/Patchbox/pb-rack-planner-v3/frontend/pages/account-deleted.vue").then(m => m.default || m)
  },
  {
    name: "account",
    path: "/account",
    meta: accountu1ihb6BIoxMeta || {},
    component: () => import("/Users/laurentlang/Documents/Websites/Online/Patchbox/pb-rack-planner-v3/frontend/pages/account.vue").then(m => m.default || m)
  },
  {
    name: "dashboard",
    path: "/dashboard",
    meta: dashboardTuoGKyjkPRMeta || {},
    component: () => import("/Users/laurentlang/Documents/Websites/Online/Patchbox/pb-rack-planner-v3/frontend/pages/dashboard.vue").then(m => m.default || m)
  },
  {
    name: "forgot-password",
    path: "/forgot-password",
    component: () => import("/Users/laurentlang/Documents/Websites/Online/Patchbox/pb-rack-planner-v3/frontend/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/Users/laurentlang/Documents/Websites/Online/Patchbox/pb-rack-planner-v3/frontend/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    component: () => import("/Users/laurentlang/Documents/Websites/Online/Patchbox/pb-rack-planner-v3/frontend/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "password-reset-success",
    path: "/password-reset-success",
    component: () => import("/Users/laurentlang/Documents/Websites/Online/Patchbox/pb-rack-planner-v3/frontend/pages/password-reset-success.vue").then(m => m.default || m)
  },
  {
    name: "plan",
    path: "/plan",
    component: () => import("/Users/laurentlang/Documents/Websites/Online/Patchbox/pb-rack-planner-v3/frontend/pages/plan.vue").then(m => m.default || m)
  },
  {
    name: "quote-success",
    path: "/quote-success",
    component: () => import("/Users/laurentlang/Documents/Websites/Online/Patchbox/pb-rack-planner-v3/frontend/pages/quote-success.vue").then(m => m.default || m)
  },
  {
    name: "quote",
    path: "/quote",
    meta: quoteFSFyScYH7WMeta || {},
    component: () => import("/Users/laurentlang/Documents/Websites/Online/Patchbox/pb-rack-planner-v3/frontend/pages/quote.vue").then(m => m.default || m)
  },
  {
    name: "register",
    path: "/register",
    component: () => import("/Users/laurentlang/Documents/Websites/Online/Patchbox/pb-rack-planner-v3/frontend/pages/register.vue").then(m => m.default || m)
  },
  {
    name: "sandbox",
    path: "/sandbox",
    component: () => import("/Users/laurentlang/Documents/Websites/Online/Patchbox/pb-rack-planner-v3/frontend/pages/sandbox.vue").then(m => m.default || m)
  },
  {
    name: "useraction",
    path: "/useraction",
    component: () => import("/Users/laurentlang/Documents/Websites/Online/Patchbox/pb-rack-planner-v3/frontend/pages/useraction.vue").then(m => m.default || m)
  }
]